@import url(https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;700&display=swap);
:root {
  --primary-white: #ffffff;
  --primary-orange: #f9ab00;
  --primary-grey: #ccd1d9;
  --primary-light-grey: #f4f4f4;
  --primary-black: #000000;
  --primary-dark-grey: #666666;
  --primary-dark: rgb(52, 58, 64);
  --parimary-red: #ef4035;
  --primary-hover-red: #f33501;
  --primary-transparent-black: hsla(0, 0%, 0%, 0.7);
  --primary-font: "Roboto Condensed", sans-serif !important;
}

body {
  font-family: "Roboto Condensed", sans-serif;
}
@media screen and (min-width: 768px) {
  h1 {
    font-size: 4.5rem !important;
  }
}
@media screen and (max-width: 767px) {
  h1 {
    font-size: 3.5rem !important;
  }
}
/****************************** NAVBAR *****************************/

.logo {
  width: 4.25rem;
}
.header-navbar-color {
  background: #000000;
  background: var(--primary-black);
  box-shadow: 0px 0px 20px 1px #ffffff;
  box-shadow: 0px 0px 20px 1px var(--primary-white);
}
.blink_me {
  background: linear-gradient(
    to right,
    #f9ab00 30%,
    #ef4035 100%
  );
  background: linear-gradient(
    to right,
    var(--primary-orange) 30%,
    var(--parimary-red) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: bolder;
}
/*************************** MENU LINKS *****************************/

nav {
  z-index: 2;
}

nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav > li > a {
  margin-right: 0.75rem;
  margin-left: 0.75rem;
  color: #ffffff !important;
  color: var(--primary-white) !important;
  border-top: 0.1875rem solid transparent !important ;
}

.navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
}

@media screen and (min-width: 992px) {
  .navbar-nav > li > a:hover {
    border-top: 0.1875rem solid #f9ab00 !important ;
    border-top: 0.1875rem solid var(--primary-orange) !important ;
  }
  .navbar-nav > li > a.active,
  .navbar-nav > li > a:focus {
    border-top: 0.1875rem solid #f9ab00 !important ;
    border-top: 0.1875rem solid var(--primary-orange) !important ;
  }
}
@media screen and (max-width: 991px) {
  .navbar-nav > li > a:hover {
    color: #f9ab00 !important ;
    color: var(--primary-orange) !important ;
  }
}

/*************************** HEADER *****************************/

.header-wraper {
  position: relative;
  background: url(/static/media/background_3.c57647f5.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.main-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  font-family: "Roboto Condensed", sans-serif;
  z-index: 1;
}
.main-info h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: capitalize;
}
.header-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #000000;
  background-color: var(--primary-black);
  opacity: 0.7;
}

@media screen and (max-width: 767px) {
  .main-info h1 {
    font-size: 2.5rem;
  }
  .header-wraper {
    background-position: left;
  }
}
@media screen and (min-width: 768px) {
  .main-info h1 {
    font-size: 4.5rem;
  }
}

/*************************** TYPED TEXT *****************************/

.typed-text {
  font-size: 2rem;
  color: #ffffff;
  color: var(--primary-white);
}

@media screen and (max-width: 768px) {
  .typed-text {
    font-size: 1.3rem;
  }
}

/*************************** TYPED TEXT *****************************/
.btn-main-offer {
  border: 2px solid #ef4035;
  border: 2px solid var(--parimary-red);
  text-transform: capitalize;
  border-radius: 0;
  padding: 0.75rem 0.75rem;
  color: #ffffff !important;
  color: var(--primary-white) !important;
  line-height: normal;
  display: flex;
  margin: 0.5rem;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  font-size: 20px;
}
.btn-main-offer:first-child {
  background: #f33501;
  background: var(--primary-hover-red);
}
.header-btns {
  display: flex;
  margin: 1.5rem;
}
.btn-main-offer span {
  padding-right: 15px;
}
#contact-submit-spinner {
  display: none;
}

.btn-main-offer:hover {
  text-decoration: none;
  background-color: #ef4035;
  background-color: var(--parimary-red);
  transition: 0.2s ease-in-out;
}
.blink_me {
  -webkit-animation: blinker 0.5s linear infinite !important;
          animation: blinker 0.5s linear infinite !important;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0.5;
  }
}

@keyframes blinker {
  50% {
    opacity: 0.5;
  }
}
@-webkit-keyframes blinker_btn {
  50% {
    opacity: 1;
  }
}
@keyframes blinker_btn {
  50% {
    opacity: 1;
  }
}

.hire_btn_blink {
  /*animation: blinker_btn 0.5s linear infinite;*/
  border: 2px solid #ef4035;
  border: 2px solid var(--parimary-red);
}

/*************************** PARTICLES JS *****************************/

.tsparticles-canvas-el {
  position: absolute;
  z-index: 1;
}

/*************************** About me *****************************/
#about {
  background: #f4f4f4;
  background: var(--primary-light-grey);
}
.author {
  border-radius: 100%;
  max-width: 100%;
  width: 100%;
  height: auto;
  padding: 0.3rem;
  border: 1px solid #ef4035;
  border: 1px solid var(--parimary-red);
}
.about-me {
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}

.about-me-title {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
  font-weight: 400 !important;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}

@media screen and (max-width: 767px) {
  .about-me_container {
    display: flex !important;
  }
  .about-image-container {
    justify-content: center;
  }
  .about-me-title {
    display: flex;
    justify-content: center;
  }
}

/*************************** Services *****************************/

.services {
  text-align: center;
  padding-bottom: 2rem;
  background: #f4f4f4;
  background: var(--primary-light-grey);
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}
.services .box:hover {
  color: white;
}
.services h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
  font-weight: 400 !important;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}
.services .circle {
  position: relative;
  margin: 0.375rem auto;
  background: #f9ab00;
  background: var(--primary-orange);
  border-radius: 50%;
  width: 3.125rem;
  height: 3.125rem;
}
.services .icon {
  font-size: 1.4rem;
  color: #ffffff;
  color: var(--primary-white);
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.services .box {
  box-shadow: inset 0 0 100px 0px #ffffff;
  box-shadow: inset 0 0 100px 0px var(--primary-white);
  height: 16rem;
  border-bottom: 0.3125rem solid #f9ab00;
  border-bottom: 0.3125rem solid var(--primary-orange);
  padding: 0.625rem;
  margin-bottom: 1.875rem;
  background: #ffffff;
  background: var(--primary-white);
  transition: 0.2s ease-in-out;
}
.services .box:hover {
  background: #000000;
  background: var(--primary-black);
  border-bottom: 0.3125rem solid rgb(52, 58, 64);
  border-bottom: 0.3125rem solid var(--primary-dark);
}
.services .box:hover .icon {
  color: rgb(52, 58, 64);
  color: var(--primary-dark);
}
.services .box:hover .circle {
  background: #ffffff;
  background: var(--primary-white);
}
/****************************** Experience *****************************/
.experience {
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
  padding: 0.7rem;
}
.experience-wrapper {
  position: relative;
  padding: 3.125rem 0;
  overflow: hidden;
}
.experience h1 {
  text-transform: uppercase;
  color: #f9ab00;
  color: var(--primary-orange);
}

.experience-wrapper::before {
  position: absolute;
  content: "";
  top: 0;
  left: 50%;
  margin-left: -0.0625rem;
  width: 0.125rem;
  height: 100%;
  background: #ccd1d9;
  background: var(--primary-grey);
  z-index: 1;
}
.timeline-block {
  width: calc(50% + 0.5rem);
  display: flex;
  justify-content: space-between;
  clear: both;
}
.timeline-block-left {
  float: left;
  direction: rtl;
}
.timeline-block-right {
  float: right;
}

.marker {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 0.3125rem solid #ef4035;
  border: 0.3125rem solid var(--parimary-red);
  background: #ffffff;
  background: var(--primary-white);
  margin-top: 0.625rem;
  z-index: 3;
}
.timeline-content {
  width: 95%;
  padding: 0 0.9375rem;
  color: #666666;
  color: var(--primary-dark-grey);
  direction: ltr;
}
.timeline-content h3 {
  margin-top: 0.3125rem;
  color: #ef4035;
  color: var(--parimary-red);
  font-weight: 900;
}

/****************************** Media *****************************/
@media screen and (max-width: 767px) {
  .experience-wrapper::before {
    left: 0.5rem;
    margin-bottom: 1.875rem;
  }
  .container.experience-wrapper {
    padding-left: 0;
  }

  .timeline-block-left {
    float: none;
    width: 100%;
    direction: ltr;
  }
  .timeline-block-right {
    float: none;
    direction: ltr;
    width: 100%;
  }
}

/****************************** Portfolio *****************************/
.portfolio-wrapper {
  background: #f4f4f4;
  background: var(--primary-light-grey);
  padding: 3rem 0;
}
.portfolio-image-box svg {
  cursor: pointer;
}
.portfolio-wrapper h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  font-weight: normal;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}
.portfolio-image {
  width: 100%;
  border: 1px solid #f9ab00;
  border: 1px solid var(--primary-orange);
  padding: 0.5rem;
  height: auto;
}
.portfolio-image-box {
  position: relative;
  margin: 1rem;
}
.overflow {
  position: absolute;
  opacity: 0;
  background: #000000;
  background: var(--primary-black);
  width: 100%;
  height: 100%;
  top: 0;
  border: 1px solid #ef4035;
  border: 1px solid var(--parimary-red);
  cursor: pointer;
}
.portfolio-image-box:hover .overflow {
  opacity: 60%;
  transition: 0.2s ease-in-out;
}

.portfolio-icon {
  position: absolute;
  opacity: 0;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  color: #f9ab00;
  color: var(--primary-orange);
  font-size: 2rem;
}
.portfolio-image-box:hover .portfolio-icon {
  opacity: 70%;
  transition: 0.2s ease-in-out;
}
@media screen and (max-width: 767px) {
  .image-box-wrapper.row.justify-content-center > div {
    flex: calc(100% - 2rem) 1;
  }
}
@media screen and (min-width: 768px) and (min-width: 1024px) {
  .image-box-wrapper.row.justify-content-center > div {
    flex: calc(50% - 2rem) 1;
  }
}
@media screen and (min-width: 1025px) {
  .image-box-wrapper.row.justify-content-center > div {
    flex: calc(33.33% - 2rem) 1;
  }
}

/****************************** Popup *****************************/
.portfolio-image-popupbox {
  width: 100%;
  padding: 0 0.5rem;
}
.popupbox-wrapper {
  margin-top: 70px;
}
.popupbox-wrapper .popupbox-content {
  max-width: 1600px;
}
.popupbox-wrapper {
  margin: 0 !important;
  max-width: calc(100% - 0px) !important;
}
.popupbox.is-active {
  z-index: 1111;
}
.hyper-link {
  cursor: pointer;
  color: var(--parimary-orange);
}
.hyper-link:hover {
  color: #ef4035;
  color: var(--parimary-red);
}
@media screen and (max-width: 767px) {
  .popupbox-content {
    padding: 20px 0px 30px !important;
  }
}
.freelance-profiles a a[target="_blank"]::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
  margin: 0 3px 0 5px;
}

/****************************** TESTIMONIALS *****************************/
.testimonials {
  background-image: url(/static/media/testimonials-bg.7d88caf6.jpg);
  background-size: cover;
  background-color: #000000;
  background-color: var(--primary-black);
  text-align: center;
  padding-bottom: 4rem;
  margin-bottom: 0;
  width: 100%;
}
.testimonials h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  padding: 3rem 0;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}
.testimonials-content {
  text-align: center;
  max-width: 53.125rem;
  margin: 0 auto 2rem auto;
  background: hsla(0, 0%, 0%, 0.7);
  background: var(--primary-transparent-black);
}
.myCarousel h3 {
  margin-top: 10px;
}
.myCarousel {
  color: #ffffff;
  color: var(--primary-white);
  background: transparent;
  margin-top: -6%;
  width: 90%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 6%;
  padding-bottom: 8%;
  padding-left: 5%;
  padding-right: 5%;
  height: auto;
}

.carousel .slide img {
  width: 5rem !important;
  border-radius: 50%;
}
.myCarousel h3 {
  color: #f9ab00;
  color: var(--primary-orange);
  font-weight: 600;
  text-transform: uppercase;
}
.myCarousel p {
  color: #ccd1d9;
  color: var(--primary-grey);
}

.carousel-root {
  margin: auto !important;
  margin-top: 3% !important;
}
.carousel .slide {
  background: transparent !important;
  height: 100% !important;
  padding-top: 2rem;
}
.asdasd {
  color: red;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
  border-color: #f9ab00 !important;
  border-color: var(--primary-orange) !important;
}
@media screen and (max-width: 991px) {
  .myCarousel {
    width: 100% !important;
  }
}
/****************************** Contact component *****************************/
.contacts {
  background: rgb(52, 58, 64);
  background: var(--primary-dark);
  padding: 3rem 1.5rem;
}
.contacts .contact-form {
  padding: 20px;
}

.contacts h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  padding: 1rem 0;
  text-transform: uppercase;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}
.contacts p {
  color: #f4f4f4;
  color: var(--primary-light-grey);
  font-size: 1.2rem;
}

#myDivSuccess {
  display: none;
  color: #007b00;
  font-size: 1rem;
  padding: 15px;
  margin: 1rem auto;
  line-height: 1;
  font-weight: 800;
  background: #ffffff;
  background: var(--primary-white);
  box-shadow: 0px 0px 10px 5px #ffffff;
  box-shadow: 0px 0px 10px 5px var(--primary-white);
  text-align: center;
}
.contacts input::-webkit-input-placeholder, .contacts textarea::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccd1d9;
  color: var(--primary-grey);
  opacity: 1; /* Firefox */
}
.contacts input:-ms-input-placeholder, .contacts textarea:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccd1d9;
  color: var(--primary-grey);
  opacity: 1; /* Firefox */
}
.contacts input::placeholder,
.contacts textarea::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccd1d9;
  color: var(--primary-grey);
  opacity: 1; /* Firefox */
}

.contacts textarea:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccd1d9;
  color: var(--primary-grey);
}

.contacts input:-ms-input-placeholder,
.contacts textarea::placeholder {
  /* Internet Explorer 10-11 */
  color: #ccd1d9;
  color: var(--primary-grey);
}

.contacts textarea:-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccd1d9;
  color: var(--primary-grey);
}

.contacts input::-ms-input-placeholder,
.contacts textarea::placeholder {
  /* Microsoft Edge */
  color: #ccd1d9;
  color: var(--primary-grey);
}

.contact-form {
  position: relative;
}
.contacts .submit-contact-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.spinner__inner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.submit-contact-spinner {
  display: none;
}
@media screen and (max-width: 767px) {
  .contacts .container-padding {
    padding-bottom: 1rem !important;
  }
}
.contacts .spinner-border {
  width: 5rem;
  height: 5rem;
}
/*--------------------------Inputs ---------------------*/
.contacts input,
.contacts textarea {
  width: 100%;
  box-sizing: border-box;
  background: none;
  resize: none;
  border: 0;
  border-bottom: 0.125 solid #666666;
  border-bottom: 0.125 solid var(--primary-dark-grey);
  border-radius: 0;
  color: #f9ab00;
  color: var(--primary-orange);
  outline: 0 !important;
  box-shadow: none !important;
  margin-bottom: 1rem;
  /*padding-left: 0 !important;*/
}
.contacts .container {
  padding: 4rem 1rem;
  margin-top: 2rem;
  box-shadow: 0px 0px 20px 5px #f9ab00;
  box-shadow: 0px 0px 20px 5px var(--primary-orange);
  border-radius: 50px;
}
.contacts input:focus,
.contacts textarea:focus {
  background: #666666;
  background: var(--primary-dark-grey);
  color: #f9ab00;
  color: var(--primary-orange);
  border-bottom: 0.125rem solid #666666;
  border-bottom: 0.125rem solid var(--primary-dark-grey);
}
.contacts textarea {
  height: 12.2rem !important;
}
.contact-btn {
  margin: 1rem 0 0 0;
  width: 100%;
  background: none;
}
.line {
  position: relative;
  width: 0;
  top: -0.8rem;
  height: 0.125rem;
  background: #f9ab00;
  background: var(--primary-orange);
  display: inline-block;
}
input:focus + .line,
textarea:focus + .line {
  width: 100%;
  transition: width 0.5s ease-in-out;
}
input.form-control {
  margin: -0.3rem 0;
}
textarea.form-control {
  margin: -0.3rem 0;
}
form#contactForm .form-control {
  border: 1px solid rgb(255 255 255 / 0.08);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

/*----------Media------------*/
@media (max-width: 767px) {
  .contacts {
    overflow-x: hidden;
  }
  .contacts .container {
    padding: 0 1rem;
  }
  .contacts p {
    padding: 0 0.5rem;
  }
}

/****************** FOOTER  COMPONENT **********************/
.footer {
  background: #000000;
  background: var(--primary-black);
  color: #ffffff;
  color: var(--primary-white);
  padding: 3rem;
}

.footer a {
  text-decoration: none;
  color: #ffffff;
  color: var(--primary-white);
}

.footer a:hover {
  text-decoration: none;
  color: #f9ab00;
  color: var(--primary-orange);
  cursor: pointer;
}

.footer p {
  margin-bottom: 0;
}
@media screen and (max-width: 991px) {
  .footer .footer-links {
    flex-direction: column;
  }
  .footer .links-title {
    margin-top: 1rem;
  }
  .footer .footer-links .col:last-child {
    margin-bottom: 1rem;
  }
}

/****************************** hire me component *****************************/

.hire__me-wrapper h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  text-transform: uppercase;
  font-weight: 400 !important;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
}
.hire__me-wrapper h1 {
  color: #f9ab00;
  color: var(--primary-orange);
  font-weight: normal;
  font-family: "Roboto Condensed", sans-serif;
  font-family: var(--primary-font);
  text-transform: uppercase;
  padding-bottom: 1rem;
}
.freelance-profiles {
  justify-content: center !important;
}
.freelance-profiles .fiverr,
.freelance-profiles .upwork {
  margin: 1rem 2rem;
  box-shadow: 0px 0px 20px 5px #afafaf;
  padding: 1.5rem;
  border-radius: 100px;
}
.freelance-profiles img {
  width: 100%;
  height: auto;
  max-width: 200px;
}

/****************************** fixed social component *****************************/

.fixed-social-links {
  position: fixed;
  top: 45%;
  display: flex;
  flex-direction: column-reverse !important;
  right: 0;
  z-index: 100;
}
.fixed-social-links a {
  margin-left: auto !important;
  box-shadow: 0 0 10px 0px #f9ab00;
  box-shadow: 0 0 10px 0px var(--primary-orange);
}
.github-img {
  width: 100%;
  max-width: 36px;
  background: #ffffff;
  background: var(--primary-white);
}
.fixed-social-links .facebook svg,
.fixed-social-links .facebook {
  border-top-left-radius: 10px;
}
.fixed-social-links .linkedin svg,
.fixed-social-links .linkedin {
  border-bottom-left-radius: 10px;
}
#portfolio {
  padding-top: 0;
  margin-top: -4rem;
}
div#contact > .container {
  max-width: 1000px !important;
}

